<template>
  <div class="CardMessage">
    <SingleCard class="noContentCard">
      <div dpadding>
        <div class="icon"><iconic name="lock1a" :original="true" /></div>
        <div class="text">{{ message }}</div>
      </div>
    </SingleCard>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style lang="scss">
.CardMessage {
  @include animation("fadeIn", 0.3s, ease-in-out);
}
</style>
